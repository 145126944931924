import React, { useEffect, useState } from 'react';

import { Button, Col, Divider, Form, Input, Row, Select, Spin } from 'antd';

import { useIntegration } from '@hooks/IntegrationContext';

import { IIntegrationForm } from './models/IIntegrationForm';

interface ManufacturerIntegrationProps {
  onSubmit: Function;
}

const ManufacturerIntegration: React.FC<ManufacturerIntegrationProps> = ({ onSubmit }) => {
  const { loading, currentIntegration } = useIntegration();
  const [form] = Form.useForm();
  const [typesIntegrations] = useState<any[]>([
    { id: 1, name: 'TOTVS Modas' },
    { id: 2, name: 'MERP' },
  ]);

  const [selectedIntegrationSystem, setSelectedIntegrationSystem] = useState<number>(1);
  const [typesAuthentications] = useState<any[]>([{ id: 1, name: '1' }]);
  const [intervals] = useState([15, 30, 45, 60]);
  const { Option } = Select;
  const [regexUrl] = useState<RegExp>(/(https?):\/\/.{2,50}\..{2,400}[\w\-]$/i);

  const handleFormSubmit = async () => {
    const values: IIntegrationForm = await form.validateFields();

    if (values.integrationSystem === 2) values.headOfficeCnpj = values?.headOfficeCnpj?.replace(/\D/g, '');

    const success = await onSubmit(values);
    if (success) form.resetFields();
  };

  const flavors = [
    { id: 'Modavest', name: 'Modavest' },
    { id: 'RapidSales', name: 'RapidSales' },
  ];

  useEffect(() => {
    if (currentIntegration != null) {
      form.setFields([
        { name: 'integrationId', value: currentIntegration.integrationId },
        { name: 'integrationName', value: currentIntegration.integrationName },
        { name: 'integrationSyncInterval', value: currentIntegration.integrationSyncInterval },
        { name: 'integrationSystem', value: currentIntegration.integrationSystem },
        { name: 'integrationAuthSystem', value: currentIntegration.integrationAuthSystem },
        { name: 'integrationUrl', value: currentIntegration.integrationUrl },
        { name: 'integrationGrantType', value: currentIntegration.integrationGrantType },
        { name: 'integrationClientId', value: currentIntegration.integrationClientId },
        { name: 'integrationUsername', value: currentIntegration.integrationUsername },
        { name: 'integrationClientSecret', value: currentIntegration.integrationClientSecret },
        { name: 'integrationPassword', value: currentIntegration.integrationPassword },
        { name: 'integrationContactName', value: currentIntegration.integrationContactName },
        { name: 'integrationEmail', value: currentIntegration.integrationEmail },
        { name: 'integrationPhone', value: currentIntegration.integrationPhone },
        { name: 'integrationFlavor', value: currentIntegration.integrationFlavor },
        { name: 'headOfficeCnpj', value: currentIntegration.headOfficeCnpj },
        { name: 'headOfficeFantasyName', value: currentIntegration.headOfficeFantasyName },
        { name: 'headOfficeName', value: currentIntegration.headOfficeName },
      ]);
    } else {
      form.resetFields();
    }
  }, [currentIntegration]);

  const formatCnpj = (value: string) => {
    if (value) {
      const cnpjRegex = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;
      const numericValue = value.replace(/\D/g, '');

      if (numericValue.length === 14) {
        form.setFieldsValue({
          headOfficeCnpj: value.replace(cnpjRegex, '$1.$2.$3/$4-$5'),
        });
      } else {
        form.setFieldsValue({
          headOfficeCnpj: numericValue,
        });
      }
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      integrationSystem: selectedIntegrationSystem,
    });
  }, [selectedIntegrationSystem]);

  return (
    <Spin spinning={loading}>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleFormSubmit}
        autoComplete="off"
        initialValues={{
          integrationId: null,
          integrationSystem: 1,
          integrationAuthSystem: 1,
          integrationSyncInterval: 15,
        }}
      >
        <Row align="middle" justify="space-between" className="my-0">
          <Col span={12}>
            <Form.Item
              name="integrationContactName"
              label="Nome do contato"
              className="mb-2 mr-5"
              rules={[
                {
                  required: true,
                  message: 'Informe o nome de contato',
                },
              ]}
            >
              <Input placeholder="Informe um nome" className="input" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="integrationEmail"
              label="Email"
              className="mb-2 mr-5"
              rules={[
                {
                  type: 'email',
                  required: true,
                  message: 'Informe um email',
                },
              ]}
            >
              <Input placeholder="Informe um email" className="input" type="email" />
            </Form.Item>
          </Col>
        </Row>
        <Row align="middle" justify="space-between" className="my-0">
          <Col span={12}>
            <Form.Item
              name="integrationPhone"
              label="Telefone"
              className="mb-2 mr-5"
              rules={[{ pattern: /^\d*$/, message: 'Digite apenas números', required: true }]}
            >
              <Input placeholder="Informe um telefone" className="input" maxLength={11} />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row align="middle" justify="space-between" className="my-0">
          <Col span={12}>
            <Form.Item className="d-none" name="integrationId">
              <Input type="hidden" />
            </Form.Item>
            <Form.Item
              name="integrationSystem"
              label="Sistema de integração"
              className="mb-2 mr-5"
              rules={[{ required: true, message: 'Informe o sistema de integração' }]}
            >
              <Select
                onChange={(value) => setSelectedIntegrationSystem(value)}
                placeholder="Selecione o sistema de integração"
              >
                {typesIntegrations.map((typeIntegration) => (
                  <Option key={typeIntegration.id} value={typeIntegration.id}>
                    {typeIntegration.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="integrationName"
              label="Nome da integração"
              className="mb-2"
              rules={[{ required: true, message: 'Informe o nome da integração' }]}
            >
              <Input placeholder="Informe o nome da integração" className="input" />
            </Form.Item>
          </Col>
        </Row>

        {selectedIntegrationSystem === 1 && (
          <Row align="middle" justify="space-between" className="my-0">
            <Col span={12}>
              <Form.Item
                name="integrationAuthSystem"
                label="Sistema de autenticação"
                className="mb-2 mr-5"
                rules={[
                  {
                    required: selectedIntegrationSystem === 1 ? true : false,
                    message: 'Informe o sistema de autenticação',
                  },
                ]}
              >
                <Select placeholder="Selecione o sistema de autenticação">
                  {typesAuthentications.map((typeAuth) => (
                    <Option key={typeAuth.id} value={typeAuth.id}>
                      {typeAuth.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="integrationSyncInterval"
                label="Intervalo de sincronização"
                className="mb-2"
                rules={[{ required: selectedIntegrationSystem === 1 ? true : false, message: 'Informe um intervalo' }]}
              >
                <Select placeholder="Selecione um intervalo">
                  {intervals.map((interval, index) => (
                    <Option key={index} value={interval}>
                      {interval}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}

        {selectedIntegrationSystem === 1 && (
          <Row align="middle" justify="space-between" className="my-0">
            <Col span={12}>
              <Form.Item
                name="integrationUrl"
                label="URL"
                className="mb-2 mr-5"
                rules={[
                  {
                    required: selectedIntegrationSystem === 1 ? true : false,
                    message: 'Informe a URL',
                    whitespace: false,
                  },
                  {
                    pattern: regexUrl,
                    message: 'URL inválida! Verifique se não há espaços ou barra no fim',
                  },
                ]}
              >
                <Input placeholder="Informe a URL" className="input" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="integrationGrantType"
                label="Grant type"
                className="mb-2"
                rules={[{ required: selectedIntegrationSystem === 1 ? true : false, message: 'Informe o grant type' }]}
              >
                <Input placeholder="Informe o grant type" className="input" />
              </Form.Item>
            </Col>
          </Row>
        )}

        {selectedIntegrationSystem === 1 && (
          <Row align="middle" justify="space-between" className="my-0">
            <Col span={12}>
              <Form.Item
                name="integrationClientId"
                label="Id do cliente"
                className="mb-2 mr-5"
                rules={[
                  { required: selectedIntegrationSystem === 1 ? true : false, message: 'Informe o id do cliente' },
                ]}
              >
                <Input placeholder="Informe a id do cliente" className="input" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="integrationUsername"
                label="Nome de usuário"
                className="mb-2"
                rules={[
                  { required: selectedIntegrationSystem === 1 ? true : false, message: 'Informe o nome de usuário' },
                ]}
              >
                <Input placeholder="Informe o nome de usuário" className="input" />
              </Form.Item>
            </Col>
          </Row>
        )}

        {selectedIntegrationSystem === 1 && (
          <Row align="middle" justify="space-between" className="my-0">
            <Col span={12}>
              <Form.Item
                name="integrationClientSecret"
                label="Client_Secret"
                className="mb-2 mr-5"
                rules={[
                  { required: selectedIntegrationSystem === 1 ? true : false, message: 'Informe o Client Secret' },
                ]}
              >
                <Input placeholder="Informe o Client Secret" className="input" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="integrationPassword"
                label="Senha"
                className="mb-2"
                rules={[{ required: selectedIntegrationSystem === 1 ? true : false, message: 'Informe a senha' }]}
              >
                <Input placeholder="Informe a senha" type="password" className="input" />
              </Form.Item>
            </Col>
          </Row>
        )}

        {/* SOMENTE SE A OPÇÃO SELECIONADA FOR MERP */}
        {selectedIntegrationSystem === 2 && (
          <Row align="middle" justify="space-between" className="my-0">
            <Col span={12}>
              <Form.Item
                name="headOfficeCnpj"
                label="CNPJ"
                className="mb-2 mr-5"
                rules={[
                  {
                    pattern: /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/,
                    required: selectedIntegrationSystem === 2 ? true : false,
                    message: 'Informe o CNPJ corretamente',
                  },
                ]}
              >
                <Input
                  placeholder="Informe o CNPJ"
                  onChange={(event) => formatCnpj(event.target.value)}
                  className="input"
                  maxLength={18}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="headOfficeFantasyName"
                label="Nome da matriz"
                className="mb-2"
                rules={[
                  { required: selectedIntegrationSystem === 2 ? true : false, message: 'Informe o nome da matriz' },
                ]}
              >
                <Input placeholder="Informe o nome da matriz" className="input" />
              </Form.Item>
            </Col>
          </Row>
        )}

        {selectedIntegrationSystem === 2 && (
          <Row align="middle" justify="space-between" className="my-0">
            <Col span={12}>
              <Form.Item
                name="headOfficeName"
                label="Razão social"
                className="mb-2 mr-5"
                rules={[
                  { required: selectedIntegrationSystem === 2 ? true : false, message: 'Informe a razão social' },
                ]}
              >
                <Input placeholder="Informe a razão social" className="input" />
              </Form.Item>
            </Col>
          </Row>
        )}
        {/* --- */}
        <Row align="middle" justify="space-between" className="my-0">
          <Col span={12}>
            <Form.Item
              name="integrationFlavor"
              label="Flavor"
              className="mb-2 mr-5"
              rules={[{ required: true, message: 'Informe o Client Secret' }]}
            >
              <Select
                placeholder="Selecione a flavor"
                showSearch
                allowClear
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {flavors.map((falvor: { id: string; name: string }) => (
                  <Select.Option key={falvor.id} value={falvor.id}>
                    {falvor.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Button loading={loading} className="mt-4" type="primary" htmlType="submit" size="large" block>
          Salvar
        </Button>
      </Form>
    </Spin>
  );
};

export default ManufacturerIntegration;
